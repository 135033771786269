import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const query = graphql`
  query {
    allShopifyCollection {
      edges {
        node {
          id
          title
          handle
        }
      }
    }
    allDatoCmsNavigation {
      edges {
        node {
          navHandle
          navTitle
          id
        }
      }
    }
  }
`;

const ProductContext = React.createContext();
export default ProductContext;

export function ProductContextProvider({ children }) {
  const { allShopifyCollection, allDatoCmsNavigation } = useStaticQuery(query);

  return (
    <ProductContext.Provider
      value={{
        collections: allShopifyCollection?.edges.map(({ node }) => node),
        navigationPages: allDatoCmsNavigation?.edges.map(({ node }) => node),
      }}>
      {children}
    </ProductContext.Provider>
  );
}
