import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import SofiaRegularWoff from '../assets/fonts/a330d79c-2615-44af-8109-70f0fafae0b0.woff';
import SofiaRegularTff from '../assets/fonts/3649f605-deb6-4cc1-ad4b-265287e6e864.ttf';
import SofiaBoldWoff from '../assets/fonts/01f00d05-4605-41e6-9fec-a55b00e39c0d.woff';
import SofiaBoldTff from '../assets/fonts/9f875cbb-ca7a-4bdf-9d63-06ee3b53eba4.ttf';
import Bold from '../assets/fonts/Circular-Bold/CircularStd-Bold.otf';
import Book from '../assets/fonts/Circular-Bold/CircularStd-Book.otf';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Sofia W01 Bold';
    src: url(${SofiaBoldWoff}) format('woff'),
      url(${SofiaBoldTff}) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia W01 Regular';
    src: url(${SofiaRegularWoff}) format('woff'),
      url(${SofiaRegularTff}) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'Circular Bold';
    src: url(${Bold}) format('otf'), url(${Bold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Circular Book';
    src: url(${Book}) format('otf'), url(${Book}) format('truetype');
    font-display: swap;
  }

  :root {
    --dark-blue: #25272e;
    --primary-color: #3a67b1;
    --font-regular: 'Circular Book', arial, tahoma, sans-serif;
    --font-bold: 'Circular Bold', arial, tahoma, sans-serif;
  }

  * {
    max-width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  main {
    font-family: var(--font-regular);
  }

  a {
    text-decoration: none;
    font-family: var(--font-regular);
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: var(--font-bold);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--dark-blue);
  }

  p {
    line-height: 25px;
  }

  button {
    cursor: pointer;
    background: transparent;
    outline: 0;
    border: 0;
  }

  input,
  textarea,
  select {
    overflow: hidden;
    padding: 10px;
    border-width: 2px;
    border-color: black;
    width: 100%;
    font-size: 14px;
    text-align: left;
    font-family: var(--font-regular);

    &:focus {
      outline: var(--secondary);
    }

    &::placeholder {
      color: #26262e;
    }
  }

  //DOTS
  .rec.rec-dot.rec-dot_active {
    background-color: black;
    border: 3px solid black;
  }

  .rec-pagination {
    button {
      box-shadow: none;
      background-color: #eee;
      border: 3px solid #eee;
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    max-height: 100%;
    height: 100%;
    width: 100%;
    visibility: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    overflow-y: auto;
    transition: opacity 0.3s ease-in, visibility 0.3s ease-in;

    h3 {
      font-size: 1.2rem;
      font-family: var(--font-bold);
    }

    span,
    button {
      display: inline-flex;
      gap: 10px;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
    }
  }
`;
