import { css } from 'styled-components';

const theme = {
  breakpoints: {
    default: '85%',
    sm: {
      break: 576,
      max: 540,
    },
    md: {
      break: 768,
      max: 720,
    },
    lg: {
      break: 992,
      max: 960,
    },
    xl: {
      break: 1200,
      max: 1140,
    },
  },
};

export const responsive = {
  sm: {
    ...theme.breakpoints.sm,
    query: (...args) => css`
      @media (min-width: ${theme.breakpoints.sm.break}px) {
        ${css(...args)};
      }
    `,
  },
  md: {
    ...theme.breakpoints.md,
    query: (...args) => css`
      @media (min-width: ${theme.breakpoints.md.break}px) {
        ${css(...args)};
      }
    `,
  },
  lg: {
    ...theme.breakpoints.lg,
    query: (...args) => css`
      @media (min-width: ${theme.breakpoints.lg.break}px) {
        ${css(...args)};
      }
    `,
  },
};

export default theme;
