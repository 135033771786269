// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-user-js": () => import("./../../../src/pages/create-user.js" /* webpackChunkName: "component---src-pages-create-user-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-tiles-js": () => import("./../../../src/pages/tiles.js" /* webpackChunkName: "component---src-pages-tiles-js" */),
  "component---src-pages-welcome-back-js": () => import("./../../../src/pages/welcome-back.js" /* webpackChunkName: "component---src-pages-welcome-back-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/CollectionTemplate.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-privacy-terms-privacy-terms-template-js": () => import("./../../../src/templates/PrivacyTerms/PrivacyTermsTemplate.js" /* webpackChunkName: "component---src-templates-privacy-terms-privacy-terms-template-js" */),
  "component---src-templates-product-product-template-js": () => import("./../../../src/templates/Product/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-product-template-js" */)
}

