import React from 'react';
import { ProductContextProvider } from './src/context/ProductContext';
import { GlobalStyle } from './src/components/globalStyles';
import { ThemeProvider } from 'styled-components';
import theme from './src/context/theme';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ProductContextProvider>{element}</ProductContextProvider>
    </ThemeProvider>
  );
};

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
);
